// Fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,700,700i');
// Bootstrap Variables
@import "variables";
// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

//override bootstrap styles
.tab-content {
  padding-top: 10px;
}

.navbar-brand {
  padding-top: 21px;
}

.form-horizontal .control-label {
  text-align: left !important;
}

@media all and (max-width: 340px) {
  .navbar-brand {
    padding-top: 11px;

    img {
      width: 165px;
    }
  }
}

.progress {
  position: relative;
}

.progress span {
  position: absolute;
  display: block;
  width: 100%;
  color: black;
}

// App variables

$color-blue: #40BCFF;
$color-second: #2B4E65;

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 10px;
}

body {
  font-size: 1.4rem;
  line-height: 2.3rem;
  background: #fff;
  color: $color-second;
  font-family: 'Open Sans', sans-serif;
}

h2, h3 {
  margin-top: 0;
}

h2 {
  font-size: 2.2rem;
}

ul {
  margin: 0;
}

li {
  list-style: none;
}

article {
  ul {
    padding-left: 20px;
    margin-left: 50px !important;
    margin-bottom: 8px !important;
	text-indent: 10px;
    li {
      list-style-type: disc;
      list-style-position: inside;
      margin-left: 20px;
    }
  }

  img.pull-left {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

img {
  max-width: 100%;
}

a, a:hover {
  text-decoration: none;
}

label {
  font-weight: normal;
  cursor: pointer;
}

input[type=submit].btn-submit, .btn-submit, .btn-submit-o {
  display: block;
  background: $color-blue;
  border: none;
  width: 100%;
  height: 55px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  line-height: 55px;
  cursor: pointer;
}

.btn-submit-o {
  border: 1px solid $color-blue;
  background-color: transparent;
  color: $color-blue;
}

input.customized-radio {
  display: none;

  &:checked + label:after {
    background: #556D7E;
    border-radius: 50px;
    content: " ";
    font-size: 32px;
    height: 12px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 12px;
  }

  & + label {
    background-color: #fff;
    border: 1px solid #cacece;
    border-radius: 50px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 -15px 10px -12px rgba(0, 0, 0, 0.05) inset;
    cursor: pointer;
    display: inline-block;
    padding: 9px;
    position: relative;
  }
}

.tag:not(.label) {
  white-space: nowrap;
  position: relative;
  background: #337ab7;
  display: inline-block;
  margin-bottom: 2px;
  padding: 1px 20px 1px 5px;
  border-radius: 4px;
  color: #fff;
  font-size: 1.2rem;

  &:after {
    content: '';
    position: absolute;
    right: 7px;
    top: 10px;
    background: #fff;
    width: 5px;
    height: 5px;
    border-radius: 100%;
  }
}

input.customized-checkbox {
  display: none;

  &:checked + label:after {
    color: $color-second;
    content: "✔";
    font-size: 14px;
    left: 3px;
    position: absolute;
    top: -2px;
  }

  & + label {
    background-color: #fff;
    border: 1px solid #cacece;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 -15px 10px -12px rgba(0, 0, 0, 0.05) inset;
    display: inline-block;
    padding: 9px;
    position: relative;
  }
}

.bootstrap-tagsinput {
  display: block !important;
}

.alert {
  margin-top: 15px;
}

@import "dashboard";

nav.navbar {
  background: #556D7E;
  border: none;
  margin-bottom: 0 !important;

  .add-company {
    background: #40BCFF;
  }

  li {

    &.active {
      a {
        background: none !important;
        color: #40BCFF !important;
      }
    }

    a {
      color: #fff !important;
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }
}

.content {
  min-height: 600px;
}

.select-question, .checkbox-question {
  label {
    display: inline-block;
    vertical-align: middle;
    font-weight: normal;
    margin-bottom: 10px;
    &.text {
      margin-left: 5px;
      width: 89%;
    }
  }
}

.companies-index {

  .h2 {
    margin-top: 0;
  }

  .search-field {
    margin: 30px 0;
    input {
      display: block;
      border-radius: 4px 0 0 4px;
      width: 80%;
      height: 45px;
      float: left;
      line-height: 45px;
      border: 1px solid #5e6d7d;
      border-right: none;
      text-indent: 15px;
    }
    button {
      display: block;
      float: right;
      width: 20%;
      background: #75bbff;
      color: #fff;
      border: none;
      height: 45px;
      border-radius: 0 4px 4px 0;
    }
  }

  .form-group {
    margin-top: 7px;
    margin-bottom: 15px;
  }

  #filter-progress {
    display: none;
  }

  .companies-list {
    .item {
      border: 1px solid #5e6d7d;
      padding: 30px 35px 15px;
      margin-bottom: 10px;
    }

    a.read-more {
      float: right;
      background: #75bbff;
      border-radius: 12px;
      display: inline-block;
      padding: 10px;
      color: #fff;
    }

    .logo-block {
      text-align: center;
      img {
        width: 75px;
        height: 75px;
      }
    }

    .attachments-block {
      float: left;
      img {
        display: inline-block;
        vertical-align: middle;
        width: 75px;
        height: 75px;
        margin-right: 3px;
        padding: 3px;
        background: #fff;
        box-shadow: 0 0 3px #b4b4b4;
      }
    }
  }
}

.company-show {
  h2 {
    text-transform: uppercase;

    i {
      font-size: 4rem;
    }

    * {
      display: inline-block;
      vertical-align: middle;
    }
    span {
      padding-top: 4px;
      font-size: 2rem;
    }
  }

  .hero {

    padding: 100px 0 70px;
    background: #F4F7FC;
    margin-bottom: 100px;

    h1 {
      margin-top: 0;
      font-weight: bold;
    }

    img {
      border: 2px solid #7D8793;
      border-radius: 5px;
      width: 150px;
      height: 150px;
    }
  }

  .btn-submit {
    background: #75bbff !important;
  }

  .tag.company-page {
    &:after {
      display: none;
    }

    background: #5e6d7d;
    padding: 0 10px;
  }

  select {
    border: 1px solid #5e6d7d;
    padding: 10px;
    height: 45px;
  }

  .contact-data {

    padding: 70px 0;

    i {
      font-size: 4rem;
      margin-bottom: 7px;
    }

    li {
      display: inline-block;
      vertical-align: middle;
      padding: 40px 0;
      width: 23.5%;
      margin-right: 1.5%;
      text-align: center;
      color: #5e6d7d;
      border: 1px solid #5e6d7d;
      font-weight: bold;
      min-height: 152px;

      a {
        color: #75bbff;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.company-register {
  padding: 50px 0;

  .controls {
    margin-top: 25px;
  }

  .btn-submit {
    max-width: 50%;
  }
  #regions {
    columns: 3;
    -moz-columns: 3;
  }

  .right-col {
    border: 1px solid $color-second;
    padding: 20px;
    .title {
      font-weight: bold;
      font-size: 2.5rem;
      margin-bottom: 15px;
    }
    ul {
      margin-left: 20px;
    }
    li {
      margin-bottom: 15px;
      list-style-type: square;
    }
  }
}

.faq-page {
  h2 {
    margin: 20px 0;
  }
  ul li {
    list-style: decimal;
    margin-left: 30px;
  }
  ol li {
    list-style: disc;
    margin-left: 30px;
  }
  .faqquestion {
    font-size: 1.7rem;
    font-weight: bold;
    margin: 5px 0;
    cursor: pointer;
  }
  .answer {
    display: none;
    margin-bottom: 10px;
    margin-top: 3px;
    padding: 7px;
    color: #fff;
    background: #556D7E;
    margin-left: 15px;
  }
  padding: 30px 0;
}

.about-page {
  h2 {
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 2rem;
  }
  .description {
    margin-top: 25px;
  }
  .btn-submit {
    max-width: 250px;
    margin: 30px auto;
    text-transform: uppercase;
  }
}

.industry-page {

  &.extra-page {
    h2, h3 {
      margin-top: 20px;
    }
  }

  &.category-page {
    input.btn-submit {
      margin-top: 25px;
      width: 80%;
      margin-left: 10%;
    }
  }

  .questions-hero {

    #subcategory-form-questions {
      position: relative;
      min-height: 370px;
      padding-bottom: 80px;
    }

    .title {
      text-align: center;
    }

    .question-title {
      margin-bottom: 20px;
    }

    .question-block {
      display: none;
      width: 75%;
      margin-left: 12%;

      &:first-child {
        display: block;
      }

      &.min-budget-question-block {
        text-align: center;
      }
    }

    .progress {
      display: none;
      margin-top: 10px;
    }

    .help-block {
      display: none;
      color: red;
    }

    .controls {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      margin-top: 15px;
      .prev {
        display: none;
      }
    }

    .min-budget-question {
      line-height: 3.4rem;

      & > .currency {
        padding-left: 0;
      }
    }

  }

  .information {
    padding-bottom: 100px;
    
    
  }

  .hero {
    padding: 90px 0;
    background: radial-gradient(#FBFCFE, #F2F6FB);

    .title {
      font-size: 3.3rem;
      margin-bottom: 25px;
    }

    .sub-title {
      margin-bottom: 35px;
    }

  }

  .links-column {
    float: left;
    border: 1px solid $color-second;
    padding: 35px 40px;
    margin-right: 20px;
    margin-bottom: 15px;

    ul {
      margin: 15px 0 20px;
    }

    a {
      color: $color-blue;
    }

    .title {

      font-size: 2rem;

      a {
        color: $color-second;
      }
    }
  }

}

.privacy-page {
  margin-top: 30px;

  h2 {
    margin: 10px 0;
  }
}

.page- {
  &company-register {

    .landing .row {
      margin: 40px 0;
    }

    .reviews {
      margin-top: 100px;
      background: $color-blue;
      color: #fff;
      text-align: left;

      .container {
        position: relative;
        overflow: hidden;
        height: 150px;
      }

      .items {

        position: absolute;
        top: 20px;
        width: 80%;
        z-index: 5;
        left: 10%;

        .item {
          position: absolute;
          left: 0;
          top: 0;
          display: none;
          width: 100%;

          &:first-child {
            display: block;
          }
        }

        .text {
          padding-top: 35px;
        }

        .image {
          text-align: right;
        }

        .quote {
          font-size: 2rem;
        }

        .author {
          font-size: 1.2rem;
        }

        img {
          border-radius: 100%;
          border: 3px solid #ffffff;
        }
      }

      .controls {
        font-size: 4rem;
        position: relative;
        z-index: 15;

        * {
          cursor: pointer;
          position: absolute;
          top: 25px;
        }

        .prev {
          left: 50px;
        }

        .next {
          right: 50px;
        }

      }
    }

    .hero {
      padding-bottom: 0 !important;
      .title {
        font-size: 4rem;
      }
      .sub-title {
        font-size: 2.5rem;
        margin: 10px 0 30px;
      }
    }

    .demo {
      padding: 120px 0;

      .buttons {
        margin-top: 35px;
      }

      .title {
        font-size: 3rem;
        color: $color-second;
        line-height: 4rem;
        margin-bottom: 15px;
        margin-top: 20px;
      }

      .illustration {
        text-align: center;
        #use-comparendo {
          border: 1px solid $color-second;
          color: $color-second;
          display: inline-block;
          padding: 3px 10px;
          margin-top: 15px;
        }
      }
    }
  }
}

.home-page {
  .hero {
    padding: 350px 0 290px;
    text-align: center;
    background: url('../images/hero.jpg') 50% 0 /cover no-repeat;

    .title {
      font-size: 5.7rem;
      line-height: 6.4rem;

      span {
        color: $color-blue;
        font-weight: bold;
      }
    }

    .sub-title {
      font-size: 2rem;
      line-height: 3rem;
      margin: 35px 0 40px;
      font-weight: lighter;
    }

    form {
      select {
        height: 55px;
        font-size: 1.7rem;
      }
      input[type=submit] {
        height: 55px !important;
      }
    }
  }
}

.features {

  padding: 100px 0;
  text-align: center;

  .pic {
    font-size: 6rem;
    color: $color-blue;
  }

  .title {
    font-size: 2.5rem;
    color: $color-blue;
    margin: 20px 0 30px;
  }

  .sub-title {
    font-size: 2.1rem;
    color: $color-second;
    margin-bottom: 30px;
  }

  .reg {
    display: inline-block;
    color: #fff;
    padding: 10px 25px;
    font-weight: bold;
    background: $color-blue;
    margin-top: 15px;
  }

  .more-info {
    color: $color-second;
    font-size: 1.5rem;
    text-decoration: underline;
  }

  ul {
    text-align: left;
    font-size: 1.4rem;
    line-height: 2.9rem;
    margin-left: 50px;
    color: $color-second;
  }

}

.how-it-works.hidden-xs {

  text-align: center;
  padding: 75px 0 90px;
  background: url('../images/how-it-works/how-it-works.jpg') 50% 0 no-repeat;

  .title {
    font-size: 4rem;
    line-height: 4rem;
    margin-bottom: 80px;
  }

  ul {
    border-left: 5px solid #fff;
    margin-left: 30px;
    margin-top: -45px;

    li {
      display: block;
      height: 120px;
      vertical-align: top;
      padding: 42px 30px 0;
      position: relative;
      text-align: left;
      font-size: 2rem;

      &:first-child:after {
        content: '1';
      }

      &:last-child:after {
        content: '3';
      }

      &:before {
        position: absolute;
        left: -17px;
        top: 40px;
        border: 3px solid #fff;
        width: 30px;
        height: 30px;
        display: block;
        background: #fff;
        content: "";
        border-radius: 100%;
      }

      &:after {
        position: absolute;
        left: -8px;
        top: 43px;
        content: "2";
      }
    }
  }

  .images {
    margin-top: 20px;
    text-align: right;
    padding-top: 60px;
    img {
      display: inline-block;
      margin-top: -120px;
    }
  }
}
.how-it-works.visible-xs {

  text-align: center;
  padding: 55px 0 70px;
  background: #fff;

  .title {
    font-size: 4rem;
    line-height: 4rem;
    margin-bottom: 40px;
  }

  li {
    text-align: center;
    display: block;
    vertical-align: top;
    margin: 20px 30px;
    img {
      display: inline-block;
    }
  }
}

.partners {

  padding: 60px 0;

  .container {
    width: 90% !important;
    max-width: 90% !important;
  }

  .images {
    text-align: center;
    li {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .our-partners {
    position: relative;
    margin-bottom: 55px;

    .title {
      text-align: center;
      font-size: 3.5rem;
      line-height: 3.5rem;
      margin-bottom: 50px;
    }

    li {
      margin: 0 45px;
    }
  }

  .partners-companies {

    margin-top: 100px;

    .title {
      text-align: center;
      font-size: 2.5rem;
      margin-bottom: 45px;
    }

    img {
      width: 75px;
      padding-left: 10px;
      margin-left: 20px;
      max-width: 80px;

      &:last-child {
        display: none;
      }
    }
  }
}

.footer {
  background: #556D7E;
  color: #ffffff;
  padding: 90px 0 50px;
  margin-top: 30px;

  .title {
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  a {
    color: #ffffff;
    &:hover {
      color: #41BAFD;
    }
  }

  .social {
    .title {
      margin-bottom: 15px;
    }
    i {
      font-size: 3.2rem;
    }
  }

}

.container {
  max-width: 970px !important;
}

.progress {
  height: 30px !important;

  .progress-bar {
    font-size: 15px !important;
    line-height: 30px !important;
  }
}

.contact-form {
  margin-top: 30px;

  form {
    margin-top: 15px;
  }
}

@media all and (max-width: 768px) {
  .footer {
    text-align: center;
  }
  .navbar-brand {
    padding-top: 11px;
  }
}