.menu-line {
  background: #d3e0e9;
  color: #fff;
  text-align: center;
  margin-bottom: 50px;

  a {
    color: inherit;
    padding: 20px;
    display: block;
  }
  li {
    float: left;
    text-align: center;
    border-right: 1px solid #fff;

    &.active {
      background: #4097CD;
    }

    &:last-child {
      border-right: none;
    }

    i {
      display: block;
    }
  }
}

#fileupload {
  img {
    max-width: 80px;
  }
}

.right-column {
  line-height: 2.3rem;
  border: 1px solid $color-second;
  padding: 20px;
  margin-bottom: 20px;

  .title {
    font-weight: bold;
    font-size: 1.8rem;
    margin-bottom: 15px;
  }

  ul {
    margin-bottom: 45px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  li {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 10px;
  }
}

.details-rendered {
  span {
    display: block;
    font-weight: bold;
  }
}

.users-page {
  .info {
    font-size: 2rem;
    color: $color-second;
  }
}

.lead-show {
  .contact-details {
    span.blur-text {
      color: transparent;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
      user-select: none;
    }
  }
  li {
    list-style-type: disc;
    margin-left: 30px;
  }

  @media all and (min-width: 768px) {
    .lead-info {
      margin-top: -25px;
    }
  }
}

.panel-body {
  h3 {
    font-size: 2.1rem;
  }

  font-size: 1.4rem;
}

.project-show {

  .project-card {
    img {
      margin-top: 40px;
      margin-bottom: 7px;
    }
  }

  .panel li {
    list-style-type: disc;
    margin-left: 30px;
  }

  .leads, .companies {
    line-height: 2.5rem;
    h3 {
      margin-top: 0;
    }
  }

  .leads {
    margin-bottom: 30px;
    .panel-body {
      background: rgb(254, 235, 203);
    }
    .panel-footer {
      background: rgb(246, 186, 85);
    }
  }

  .companies {
    #filter-progress {
      margin-bottom: 10px;
      display: none;
    }
    form {
      margin-bottom: 15px;
      .form-group {
        width: 48% !important;
        margin-bottom: 7px;

        & > * {
          width: 90%;
        }
      }
    }
  }
}

.company-block {
  .score {
    float: right;
    border: 4px dashed #6c9eb7;
    font-size: 2rem;
    color: #6c9eb7;
    font-weight: bold;
    width: 100px;
    padding: 20px 0;
    text-align: center;
    box-shadow: 0 0 20px -9px #337ab7 inset;
    border-radius: 5px;

    @media all and (max-width: 768px) {
      float: none;
      margin: 15px 0;
      width: 100%;
    }
  }
}

#filter-form-companies {
    #filter-progress {
      margin-bottom: 10px;
      display: none;
    }
    form {
      margin-bottom: 15px;
      .form-group {
        width: 48% !important;
        margin-bottom: 7px;

        & > * {
          width: 90%;
        }
      }
    }
}

.mt-element-ribbon {
  h3 {
    margin-top: 0;
  }
}

#subcategories-list {
  margin: 10px 0;

  & + .bootstrap-tagsinput {
    margin-bottom: 10px;
    border: none !important;
    padding: 0 !important;
  }
}
form.companies {

  #subcategories-list {
    padding-top: 10px;
    columns: 2;
    -moz-columns: 2;
  }

  #regions {
    columns: 3;
    -moz-columns: 3;
  }

}